import {
  TwentyFourSupportOutlinedIcon,
  CrossedOutCardIcon,
  CallCallingIcon,
  SmsIcon,
  GlobeOutlinedIcon,
  CloudConnectionIcon,
} from '@/shared/ui/Icon/svg';

export const advantagesList = [
  {
    text: 'Incoming and outgoing calls',
    icon: CallCallingIcon
  },
  {
    text: 'Incoming and outgoing messages',
    icon: SmsIcon
  },
  {
    text: '24/7 access to our responsive customer support team',
    icon: TwentyFourSupportOutlinedIcon
  },
  {
    text: 'User-friendly control panel in multiple languages',
    icon: GlobeOutlinedIcon
  },
  {
    text: 'Access to virtual phone numbers on any device without cookies',
    icon: CloudConnectionIcon
  },
  {
    text: 'Affordable prices, transparent conditions, no roaming fees!',
    icon: CrossedOutCardIcon
  }
];