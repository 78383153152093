import styled, { css } from 'styled-components';
import Link from 'next/link';
import { FullSizeContainer } from '@/shared/ui/styled';

export const BurgerMenu = styled.div`
  color: ${(props) => props.theme.primary.text};

  @media (min-width: 1025px) {
    display: none !important;
  }
`;

export const NavLink = styled(Link) <{ $isActive?: boolean }>`
position: relative;
text-decoration: none;
padding: 0;
color: ${(props) => props.theme.primary.text};
font-weight: 400;
font-size: 16px;
line-height: 20px;
display: flex;
justify-content: space-between;
align-items: center;

${(props) =>
    props.$isActive &&
    css`
    color: ${props.theme.common.main};
    > svg {
      color: ${props.theme.common.main} !important;
    }

    &::before {
      content: '';
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${props.theme.common.main};
    }
  `}
`;

export const DownloadAppLink = styled(NavLink)`
  @media (max-width: 1096px) {
    display: none;
  }
`

export const BurgerMenuButton = styled.button`
  display: flex;
  padding: 10px;
  border-radius: 10px;
  border: ${(props) => `1px solid ${props.theme.primary.borderColor}`};
  background: transparent;
  outline: none;

  img {
    filter: invert(${(props) => Number(props.theme.mode !== 'light')});
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  white-space: nowrap;

   @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    flex: 1 1 auto;
    display: none !important;
  }
`;

export const LinksWrapper = styled.div`
  padding: 0 2rem;
  display: flex;
  grid-gap: 40px;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;

  @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    grid-gap: 30px;
    padding: 0 1rem;
  }

   @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: none;
  }
`;

export const SettingsWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 8px;
`;

export const NavMenuItem = styled.li<{ $isdropdownopen?: boolean }>`
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 35px 0;
  border-top: 1px solid ${(props) => props.theme.primary.borderColor};
  margin-left: 10px;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > svg {
      color: ${(props) => props.theme.secondary.text};
      transform: rotate(${(props) => (props.$isdropdownopen ? '-90deg' : '90deg')});
    }
  }

  ul {
    margin: ${(props) => (props.$isdropdownopen ? '0 0 0 24px !important' : '0 !important')};
    padding: 0;
    transition: 0.3s max-height ease;
    max-height: ${(props) => (props.$isdropdownopen ? '150px' : '0')};
    visibility: ${(props) => (props.$isdropdownopen ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.$isdropdownopen ? '1' : '0')};

    li {
      color: ${(props) => props.theme.secondary.text};
      padding-top: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: 0.3s all ease;

      &:first-child {
        padding-top: ${(props) => (props.$isdropdownopen ? '25px' : '0')};
      }
    }
  }

  > a {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }
`;

export const NavMenu = styled.div<{ $isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  overflow: hidden;
  max-height: 0;
  height: 100vh;
  background: ${(props) => props.theme.primary.bg};
  padding: 0 16px;
  transition: 0.3s all var(--easing-func);

  > button {
    margin: 35px 0;
    width: 100%;
    padding: 0;

    > a {
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      color: inherit;
    }
  }

  > div {
    position: relative;
    margin: 0 auto;
    max-width: 1272px;
    width: 100%;
    padding-bottom: 25px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.primary.borderColor};

    button {
      display: ${(props) => props.$isOpen ? 'flex' : 'none'};
      align-items: center;
      cursor: pointer;
      padding: 8px 4px;
      border: none;
      background: rgba(237, 240, 250, 0.1);
      filter: invert(${(props) => (props.theme.mode === 'light' ? '1' : '0')});
      border-radius: 6px;
    }
  }

  ${(props) =>
    props.$isOpen &&
    css`
      padding: 20px 16px;
      max-height: 100vh;
    `}
`;

export const Container = styled(FullSizeContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const Wrapper = styled.nav`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 65px;
  transition: 0.3s var(--easing-func);
  z-index: 7;
  background: ${(props) => props.theme.primary.navbarBg};
  backdrop-filter: blur(50px);
  color: ${(props) => props.theme.primary.text};
  border: 1px solid ${(props) => props.theme.primary.borderColor};

  ${Container} {
    @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      grid-gap: 15px;
      > div {
        padding: 1rem 0;
        border-left: none;
        flex: 0 0 auto;

        &:first-child {
          flex: 1 1 auto;
        }
      }
    }
  }
`;

export const SideWrapper = styled.div<{ $align: 'right' | 'left' }>`
  width: 1px;
  display: flex;
  justify-content: ${(props) => (props.$align === 'right' ? 'flex-end' : 'flex-start')};

  @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    width: auto;
  }
`;
