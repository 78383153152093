import { SVGProps } from 'react';
import Icon from '@/shared/ui/Icon'
import { TrustpilotStarIcon, AppleIcon, GooglePlayIcon, StarIcon } from '@/shared/ui/Icon/svg'
import type { AppRatingTypes } from '../../model';
import * as SC from './styled';

type Props = {
  type: AppRatingTypes.Platform;
  rating: any;
}

const ratingData: Record<AppRatingTypes.Platform, { title: string, icon: React.FC<SVGProps<SVGElement>> }> = {
  trustpilot: {
    title: 'Trustpilot',
    icon: TrustpilotStarIcon
  },
  apple: {
    title: 'App store',
    icon: AppleIcon
  },
  google: {
    title: 'Google Play',
    icon: GooglePlayIcon
  },
}

function Card({ type, rating }: Props) {
  return (
    <SC.Wrapper>
      <SC.RationWrapper>
        <span>{rating}</span>
        {type === 'trustpilot' ? (
          <SC.TrustpilotStarsWrapper>
            <div />
            <div>
              {Array.from(Array(5).keys()).map((idx) => (
                <SC.TrustilotStarWrapper key={idx}>
                  <Icon component={TrustpilotStarIcon} width={12} height={12} />
                </SC.TrustilotStarWrapper>
              ))}
            </div>
          </SC.TrustpilotStarsWrapper>
        ) : <SC.StarIcon $type={type} component={StarIcon} />}
      </SC.RationWrapper>
      <SC.PlatformSection>
        <SC.LogoIcon $type={type} component={ratingData[type].icon} />
        <span>{ratingData[type].title}</span>
      </SC.PlatformSection>
    </SC.Wrapper>
  )
}

export default Card;