import styled from "styled-components";
import Icon from "@/shared/ui/Icon";
import type { AppRatingTypes } from '../../model';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  min-width: 150px;
  padding: 15px 10px;
  border-radius: 10px;
  background: ${(props) => props.theme.primary.ratingCardBg};
  color: ${(props) => props.theme.primary.text};
`

export const RationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 18px;
  font-weight: 500;
`

export const PlatformSection = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 600;
`

export const TrustpilotStarsWrapper = styled.div`
  position: relative;
  width: min-content;
  height: 20px;

  > div:first-child {
    position: absolute;
    height: 20px;
    background: ${(props) => props.theme.common.trustpilot};
    width: 89%;
  }

  > div:last-child {
    display: flex;
    background: ${(props) => props.theme.common.gray};
  }
`;

export const TrustilotStarWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${(props) => props.theme.common.white};
  z-index: 1;

  &:last-child {
    border-right: none;
  }

  svg {
    fill: ${(props) => props.theme.common.white};
  }
`;

export const LogoIcon = styled(Icon) <{ $type: AppRatingTypes.Platform }>`
  fill: ${(props) => props.$type === 'trustpilot' ? props.theme.common.trustpilot : 'currentColor'};
`

export const StarIcon = styled(Icon) <{ $type: AppRatingTypes.Platform }>`
  fill: ${(props) => props.$type === 'apple' ? props.theme.common.orange : 'currentColor'};
`