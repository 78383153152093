import styled from "styled-components";
import { Container } from "@/shared/ui/styled";

export const Wrapper = styled(Container)`
  margin-top: 200px;
  margin-bottom: 200px;
  
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-top: 150px;
    margin-bottom: 150px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  > a {
    width: 256px;

    @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      width: 100%;
    }
  }
`