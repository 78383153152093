import React from 'react';
import { useSearchParams } from 'next/navigation';
import Image from 'next/image';
import { useTheme } from '@/context/ThemeContext';
import logoWhite from './assets/logo-white-min.webp';
import logoBlack from './assets/logo-black-min.webp';
import { Wrapper } from './styled';

type LogoProps = {
  alt?: string;
};

function Logo({ alt = 'eSIM Plus logo' }: LogoProps) {
  const searchParams = useSearchParams();
  const { isDarkTheme } = useTheme();

  const logoImage = React.useMemo(() => isDarkTheme ? logoWhite : logoBlack, [isDarkTheme]);

  return (
    <Wrapper href={`/?${searchParams.toString()}`}>
      <Image loading="eager" width={160} height={45} src={logoImage} alt={alt} />
    </Wrapper>
  );
}

export { Logo };
