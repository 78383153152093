'use client';

import React from 'react';
import { usePageContext } from '@/context/PageContextProvider';
import Logo from '@/entities/Logo';
import Icon from '@/shared/ui/Icon';
import { FullSizeContainer } from '@/shared/ui/styled';
import { FacebookIcon, TelegramIcon, LnIcon, WhatWeAcceptIcon } from '@/shared/ui/Icon/svg';
import {
  ListItem,
  List,
  ListTitle,
  Wrapper,
  ListWrapper,
  SocialNetworksWrapper,
  LinksWrapper,
  TopSection,
  BottomSection,
  SocialNetworksButton,
  SocialNetworksLink,
} from './styled';

function Footer() {
  const { utmString } = usePageContext();
  const utmQuery = utmString.length > 0 ? `?${utmString}` : '';

  return (
    <Wrapper>
      <TopSection>
        <FullSizeContainer>
          <div>
            <Logo />
            <SocialNetworksWrapper>
              <SocialNetworksLink target="_blank" rel="nofollow" href="https://www.facebook.com/esimplus.me/">
                <SocialNetworksButton type="button">
                  <Icon component={FacebookIcon} />
                </SocialNetworksButton>
              </SocialNetworksLink>
              <SocialNetworksLink target="_blank" rel="nofollow" href="https://www.linkedin.com/company/esimplus">
                <SocialNetworksButton type="button">
                  <Icon component={LnIcon} />
                </SocialNetworksButton>
              </SocialNetworksLink>
              <SocialNetworksLink target="_blank" rel="nofollow" href="https://t.me/esimplus_official">
                <SocialNetworksButton type="button">
                  <Icon component={TelegramIcon} />
                </SocialNetworksButton>
              </SocialNetworksLink>
            </SocialNetworksWrapper>
          </div>
          <div>
            <LinksWrapper>
              <div>
                <ListWrapper>
                  <ListTitle>Services</ListTitle>
                  <List>
                    <ListItem>
                      <a href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/virtual-phone-number${utmQuery}`}>
                        Virtual Phone Number
                      </a>
                    </ListItem>
                    <ListItem>
                      <a href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/virtual-phone-number/pricing${utmQuery}`}>
                        Pricing
                      </a>
                    </ListItem>{' '}
                    <ListItem>
                      <a href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/random-phone-number-generator${utmQuery}`}>
                        Phone Number Generator
                      </a>
                    </ListItem>
                  </List>
                </ListWrapper>
              </div>
              <div>
                <ListWrapper>
                  <ListTitle>Support</ListTitle>
                  <List>
                    <ListItem>
                      <a href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/faq${utmQuery}`}>Frequently Asked Questions</a>
                    </ListItem>{' '}
                    <ListItem>
                      <a href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/esim-supported-devices${utmQuery}`}>
                        eSIM-compatible Devices
                      </a>
                    </ListItem>
                    <ListItem>
                      <a href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/contact-us${utmQuery}`}>Contact Us</a>
                    </ListItem>{' '}
                  </List>
                </ListWrapper>
              </div>
              <div>
                <ListWrapper>
                  <ListTitle>About Us</ListTitle>
                  <List>
                    <ListItem>
                      <a href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/about-us${utmQuery}`}>About Us</a>
                    </ListItem>{' '}
                    <ListItem>
                      <a href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/reviews${utmQuery}`}>Reviews</a>
                    </ListItem>{' '}
                    <ListItem>
                      <a href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/news${utmQuery}`}>News</a>
                    </ListItem>
                  </List>
                </ListWrapper>
              </div>
            </LinksWrapper>
          </div>
        </FullSizeContainer>
      </TopSection>
      <BottomSection>
        <FullSizeContainer>
          <div>Appvillis UAB, {new Date().getFullYear()}</div>
          <ul>
            <li>
              <a href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/privacy${utmQuery}`}>Privacy Policy</a>
            </li>
            <li>
              <a href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/terms${utmQuery}`}>Terms of use</a>
            </li>
            <li>
              <a href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/refund-policy${utmQuery}`}>Refund Policy</a>
            </li>
          </ul>
          <div>
            <Icon component={WhatWeAcceptIcon} width={130} height={24} />
          </div>
        </FullSizeContainer>
      </BottomSection>
    </Wrapper>
  );
}

export { Footer };
