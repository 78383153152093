import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin: 200px 0;
  padding: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  border-radius: 10px;
  border: 1px dashed #83B5F7;
  background: #E0EAFD;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin: 150px 0;
    grid-gap: 50px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 25px;
  }
`;

export const ButtonWrapper = styled.div`
  a {
    white-space: nowrap;
    width: 256px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    width: 100%;

    a {
      width: 100%;
    }
  }
`

export const TextContainer = styled.div`
  max-width: 680px;

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #000;
    margin: 0 0 15px 0;

    @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 25px;
    }
  }

  p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333;
  }
`;
