'use client'

import * as SC from './styled';

function Section() {
  return (
    <SC.SubtextWrapper>
      Trusted by over 200,000+ businesses
    </SC.SubtextWrapper>
  )
}

export default Section