import styled from "styled-components";
import NextImage from 'next/image';
import { Container } from "@/shared/ui/styled";

export const Wrapper = styled(Container)`
  margin-top: 200px;
  margin-bottom: 200px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-top: 150px;
    margin-bottom: 150px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 50px 25px;
  margin-top: 50px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    justify-content: center;
  }
`

export const Card = styled.div`
  width: max-content;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Image = styled(NextImage)`
  width: 250px;
  height: 250px;
`

export const Title = styled.p`
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
  color: ${(props) => props.theme.primary.text};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`

export const Description = styled.p`
  margin: 0;
  color: ${(props) => props.theme.tertiary.text};
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  > a {
    width: 256px;

    @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      width: 100%;
    }
  }
`