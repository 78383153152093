'use client'

import React from 'react';
import dashboard from '@/public/assets/dashboard.png'
import { useAnalyticsContext } from '@/context/AnalyticsContext';
import { AppRatingUi } from '@/entities/app-rating';
import { useSignUp } from '@/shared/hooks';
import Button from '@/shared/ui/Button';
import * as SC from './styled';

function Header() {
  const { signUpClick, signUpLink } = useSignUp('get_started_click', { type: 'main_get_started' })
  const { sendSafeMixpanelEvent } = useAnalyticsContext();

  React.useEffect(() => {
    sendSafeMixpanelEvent('track', 'business_esimplus_show')
  }, [])

  return (
    <SC.Wrapper>
      <SC.TextContent>
        <SC.WelcomTitle>
          Welcome to
        </SC.WelcomTitle>
        <SC.Title>
          ESIM Plus for Business: <br /> #1 Virtual Phone Number Platform
        </SC.Title>
        <SC.Description>
          Empower Your Business to Receive SMS Seamlessly with Our Advanced Virtual Phone Number Service – The Future of Professional Communication Unlocked
        </SC.Description>
        <SC.ButtonWrapper>
          <Button
            onClick={signUpClick}
            as='a'
            href={signUpLink}
            label='Get Started'
          />
        </SC.ButtonWrapper>
        <SC.RatingWrapper>
          <AppRatingUi.Card type='trustpilot' rating={4.2} />
          <AppRatingUi.Card type='apple' rating={4.3} />
          <AppRatingUi.Card type='google' rating={4.2} />
        </SC.RatingWrapper>
      </SC.TextContent>
      <SC.ImageContent>
        <SC.DashboardImage src={dashboard} width={914} height={484} alt='Dashboard Image' />
      </SC.ImageContent>
    </SC.Wrapper>
  )
}

export default Header;