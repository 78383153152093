import styled from "styled-components";

export const SubtextWrapper = styled.p`
  margin-top: 150px;
  margin-bottom: 100px;
  padding: 0 15px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.secondary.text};

  @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    margin-top: 100px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-top: 35px;
    margin-bottom: 150px;
  }
`