'use client';

import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { usePageContext } from '@/context/PageContextProvider';
import { OperatingSystem } from '@/processes/operating-system';
import ThemeSwitcher from '@/features/ThemeSwitcher';
import Logo from '@/entities/Logo';
import { APPSTORE_LINK, GPLAY_LINK } from '@/shared/constants';
import { useModalControls, useSignUp } from '@/shared/hooks';
import Icon from '@/shared/ui/Icon';
import Button from '@/shared/ui/Button';
import { XMarkIcon } from '@/shared/ui/Icon/svg';
import burgerMenu from './assets/burger-menu.png';
import * as SC from './styled';

function Navbar() {
  const { utmString } = usePageContext();
  const { isIOS } = OperatingSystem.useOperatingSystem();
  const { signUpClick, signUpLink } = useSignUp('get_started_click', { type: 'head_get_started' });
  const [downloadAppHref, setDownloadAppHref] = useState(GPLAY_LINK);
  const utmQuery = utmString.length > 0 ? `?${utmString}` : '';

  const {
    isOpen: isNavMenuOpen,
    closeModal: closeNavMenu,
    openModal: openNavMenu,
  } = useModalControls(false, { disableBodyScroll: true });

  useEffect(() => {
    setDownloadAppHref(isIOS ? APPSTORE_LINK : GPLAY_LINK);
  }, [isIOS]);

  return (
    <>
      <div style={{ paddingBottom: 65 }} />
      <SC.Wrapper id="navbar">
        <SC.Container style={{ color: 'inherit' }}>
          <SC.SideWrapper $align="left">
            <Logo />
          </SC.SideWrapper>
          <SC.LinksWrapper>
            <SC.NavLink href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}${utmQuery}`}>Mobile data</SC.NavLink>
            <SC.NavLink href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/virtual-phone-number${utmQuery}`}>
              Virtual Phone Number
            </SC.NavLink>
            <SC.DownloadAppLink target="_blank" rel="nofollow" href={downloadAppHref}>
              Download the App
            </SC.DownloadAppLink>
            <SC.NavLink href="/" $isActive>
              For business
            </SC.NavLink>
          </SC.LinksWrapper>
          <SC.SideWrapper $align="right">
            <SC.SettingsWrapper>
              <ThemeSwitcher />
              <SC.ButtonsWrapper>
                <Button as="a" href={signUpLink} style={{ height: 48 }} onClick={signUpClick} label="Get Started" />
              </SC.ButtonsWrapper>
              <SC.BurgerMenu>
                <SC.BurgerMenuButton onClick={openNavMenu}>
                  <Image width={20} height={20} src={burgerMenu} alt="burger menu" />
                </SC.BurgerMenuButton>
                <SC.NavMenu $isOpen={isNavMenuOpen}>
                  <div>
                    <Logo />
                    <button type="button" onClick={closeNavMenu}>
                      <Icon width={14} height={14} component={XMarkIcon} />
                    </button>
                  </div>
                  <ul>
                    <SC.NavMenuItem>
                      <SC.NavLink href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}${utmQuery}`}>Mobile data</SC.NavLink>
                    </SC.NavMenuItem>
                    <SC.NavMenuItem>
                      <SC.NavLink href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/virtual-phone-number${utmQuery}`}>
                        Virtual Phone Number
                      </SC.NavLink>
                    </SC.NavMenuItem>
                    <SC.NavMenuItem>
                      <SC.NavLink target="_blank" rel="nofollow" href={downloadAppHref}>
                        Download the App
                      </SC.NavLink>
                    </SC.NavMenuItem>
                    <SC.NavMenuItem>
                      <SC.NavLink href="/" $isActive>
                        For business
                      </SC.NavLink>
                    </SC.NavMenuItem>
                  </ul>
                  <Button as="a" href={signUpLink} onClick={signUpClick} style={{ height: 45 }} label="Get Started" />
                </SC.NavMenu>
              </SC.BurgerMenu>
            </SC.SettingsWrapper>
          </SC.SideWrapper>
        </SC.Container>
      </SC.Wrapper>
    </>
  );
}

export { Navbar };
