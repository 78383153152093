import { useEffect, useState } from "react";
import Image from "next/image";
import AppleQrCode from '@/public/assets/apple_qr_code.png';
import GplayQrCode from '@/public/assets/gplay_qr_code.png';
import { OperatingSystem } from '@/processes/operating-system';

function QRCode() {
  const { isIOS } = OperatingSystem.useOperatingSystem();
  const [qrCodeData, setQrCodeData] = useState({ src: AppleQrCode, name: 'apple qr code' });

  useEffect(() => {
    setQrCodeData({
      src: isIOS ? AppleQrCode : GplayQrCode,
      name: `${isIOS ? 'apple' : 'google play'} qr code`,
    });
  }, [isIOS])

  return <Image src={qrCodeData.src} width={200} height={200} alt={qrCodeData.name} />
}

export default QRCode;