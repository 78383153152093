import(/* webpackMode: "eager" */ "/app/entities/BaseLayout/styled.ts");
import(/* webpackMode: "eager" */ "/app/entities/subtext/ui/Section/Section.tsx");
import(/* webpackMode: "eager" */ "/app/public/assets/avatar1.png");
import(/* webpackMode: "eager" */ "/app/public/assets/avatar2.png");
import(/* webpackMode: "eager" */ "/app/public/assets/avatar3.png");
import(/* webpackMode: "eager" */ "/app/shared/ui/styled.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/banner/ui/Section/Section.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/contact-us/ui/Section/Section.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/download-app/ui/Section/Section.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/faq/ui/Section/styled.ts");
import(/* webpackMode: "eager" */ "/app/widgets/Footer/Footer.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/header/ui/Header/Header.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/Navbar/Navbar.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/reviews/ui/Section/styled.ts");
import(/* webpackMode: "eager" */ "/app/widgets/virtual-number-sections/ui/Features/Features.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/virtual-number-sections/ui/Services/Services.tsx")