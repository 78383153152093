'use client'

import Image from "next/image";
import styled from "styled-components";

export const ReviewsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 50px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex-wrap: wrap;
  }
`

export const Review = styled.div`
  flex: 1;
  padding: 28px;
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.primary.reviews.border};
`

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 35px;
`

export const Avatar = styled(Image)`
  width: 44px;
  height: 44px;
`

export const Title = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => props.theme.primary.text};
`

export const Text = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${(props) => props.theme.primary.text};
`