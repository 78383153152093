import styled from 'styled-components';
import { Container } from '@/shared/ui/styled';

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    margin: 0;
    max-height: 570px;
    max-width: 100%;
    height: auto;
    width: auto;
  }
`;

export const Content = styled.div`
  margin-top: 50px;
  display: flex;
  gap: 50px;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex-direction: column;
  }
`;

export const AdvantagesList = styled.ul`
  display: grid;
  grid-gap: 25px;
  margin-bottom: 50px;

  li {
    display: flex;
    grid-gap: 15px;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: ${(props) => props.theme.primary.text};
  }

  svg {
    width: 24px;
    height: 24px;
    min-height: 24px;
    min-width: 24px;

    path {
      fill: ${(props) => props.theme.primary.text};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    gap: 15px;

    li {
      font-weight: 400;
    }
  }
`;

export const Wrapper = styled(Container)`
  margin-bottom: 200px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-bottom: 150px;
  }
`;

export const DownloadAppWaysWrapper = styled.div`
  display: flex;
  padding: 25px;
  background: ${(props) => props.theme.primary.downloadApp.bg};
  border-radius: 15px;
  width: max-content;

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    width: auto;
    justify-content: center;
  }
`;

export const QRCodeWrapper = styled.div`
  width: 200px;
  height: 200px;
  padding-right: 25px;
  margin-right: 25px;
  border-right: 1px solid ${(props) => props.theme.primary.borderColor};
  box-sizing: content-box;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    display: none;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;

export const Text = styled.p`
  max-width: 300px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.primary.text};
  margin: 25px 0;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    display: none;
  }
`;
