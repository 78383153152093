import styled from "styled-components";
import Image from "next/image";

export const Wrapper = styled.div`
  margin: 70px 0 0;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    margin: 35px 0 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex-direction: column;
    gap: 35px;
    margin: 20px 0 0;
    padding: 0 16px;
  }
`

export const TextContent = styled.div`
  flex: 0 0 60%;
  max-width: 850px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex: auto;
    max-width: none;
  }
`

export const DashboardImage = styled(Image)`
  position: absolute;
  height: 100%;
  width: auto;
  left: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    position: static;
    width: 100%;
    height: auto;
    flex: auto;
  }
`

export const ImageContent = styled.div`
  flex: 0 0 40%;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex: auto;
  }
`

export const Title = styled.h1`
  margin: 10px 0 25px;
  font-size: 72px;
  font-weight: 700;
  line-height: 84px;
  color: ${(props) => props.theme.primary.text};
  letter-spacing: -1.9px;
  max-width: 800px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    font-size: 48px;
    line-height: 56px;
    max-width: 550px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    max-width: none;
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.4px;
  }
`

export const WelcomTitle = styled.p`
  margin: 0;
  text-transform: uppercase;
  color: ${(props) => props.theme.common.main};
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;

  @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    font-size: 18px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    text-align: center;
  }
`

export const Description = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 50px;
  color: ${(props) => props.theme.primary.text};
  line-height: 1.3;

  @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    margin-bottom: 25px;
    font-size: 18px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-bottom: 35px;
    text-align: center;
  }
`

export const ButtonWrapper = styled.div`
  > a {
    width: 300px;

    @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      width: 100%;
    }
  }
`

export const RatingWrapper = styled.div`
  margin-top: 100px;
  display: flex; 
  gap: 5px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    margin-top: 50px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-top: 35px;
    flex-wrap: wrap;

    > div {
      flex-grow: 1;
    }

    > div:first-child {
      width: 100%;
    }
  }
`