'use client'

import сountries from '@/public/assets/сountries.png';
import delivered from '@/public/assets/delivered.png';
import person from '@/public/assets/person.png';
import { useSignUp } from '@/shared/hooks';
import Button from "@/shared/ui/Button";
import { Container, SectionTitle, Subtitle } from "@/shared/ui/styled";
import * as SC from './styled';

function Services() {
  const { signUpClick, signUpLink } = useSignUp('get_started_click', { type: 'second_block' })

  return (
    <Container>
      <SectionTitle>The Only Virtual Number Service You'll Ever Need</SectionTitle>
      <Subtitle>Experience Unparalleled Service with Our High-Quality, Flexible, and Reliable Virtual Number Solutions</Subtitle>
      <SC.ContentWrapper>
        <SC.Card>
          <SC.Image src={сountries} alt="Suported countries" width={200} height={200} />
          <SC.Title>Top-Quality Private Virtual Numbers</SC.Title>
          <SC.Description>Access high-quality private virtual numbers designed for seamless SMS reception and call handling</SC.Description>
        </SC.Card>
        <SC.Card>
          <SC.Image src={delivered} alt="Delivered" width={200} height={200} />
          <SC.Title>No More Blocks</SC.Title>
          <SC.Description>Experience high SMS deliverability from all online services without the worry of account blocks</SC.Description>
        </SC.Card>
        <SC.Card>
          <SC.Image src={person} alt="Person" width={200} height={200} />
          <SC.Title>Rent Numbers Your Way</SC.Title>
          <SC.Description>With just a few clicks, rent any number of virtual numbers for as long as you need</SC.Description>
        </SC.Card>
      </SC.ContentWrapper>
      <SC.ButtonWrapper>
        <Button
          onClick={signUpClick}
          as='a'
          href={signUpLink}
          label='Create Account'
        />
      </SC.ButtonWrapper>
    </Container>
  )
}

export default Services;