'use client'

import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 250px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex-direction: column;
    gap: 50px;
  }
`

export const SectionTitle = styled.h2`
  margin: 0;
  font-size: 48px;
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    font-size: 32px;
    text-align: center;
  }
`

export const Content = styled.div`
  flex: 1;
`

export const QAWrapper = styled.div`
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 1px solid ${(props) => props.theme.primary.borderColor};

  &:last-child {
    border: none;
    padding: 0;
    margin: 0;
  }
`

export const Question = styled.p`
  margin: 0 0 25px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: ${(props) => props.theme.primary.text};

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const Answer = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${(props) => props.theme.tertiary.text};
`