'use client'

import { useTheme } from "styled-components";
import { useAnalyticsContext } from "@/context/AnalyticsContext";
import Icon from '@/shared/ui/Icon';
import { APPSTORE_LINK, GPLAY_LINK } from '@/shared/constants';
import {
  GPlayIcon,
  GPlayBlackIcon,
  AppstoreIcon,
  AppstoreBlackIcon,
} from '@/shared/ui/Icon/svg';
import * as SC from './styled';

type Props = { placement?: string }

function Buttons({ placement }: Props) {
  const theme = useTheme();
  const { sendSafeFbqEvent, sendSafeGtagEvent, sendSafeMixpanelEvent } = useAnalyticsContext();

  const handleMarketClick = (market: string) => {
    sendSafeFbqEvent('Lead');
    sendSafeGtagEvent(`virtualnumber_${market}_click`, { placement })
    sendSafeMixpanelEvent('track', `virtualnumber_${market}_click`, { placement });
  };

  return (
    <SC.LinksWrapper>
      <a target="_blank" rel="noreferrer" href={GPLAY_LINK} onClick={() => handleMarketClick('googleplay')}>
        <Icon
          width={120}
          height={40}
          component={(theme as any).mode === 'light' ? GPlayIcon : GPlayBlackIcon}
        />
      </a>
      <a target="_blank" rel="noreferrer" href={APPSTORE_LINK} onClick={() => handleMarketClick('appstore')}>
        <Icon
          width={120}
          height={40}
          component={(theme as any).mode === 'light' ? AppstoreIcon : AppstoreBlackIcon}
        />
      </a>
    </SC.LinksWrapper>
  )
}

export default Buttons;