import styled from "styled-components";
import NextImage from 'next/image';

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  margin-top: 50px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Card = styled.div`
  width: max-content;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Image = styled(NextImage)`
  width: 200px;
  height: 200px;
`

export const Title = styled.p`
  height: 48px;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
  color: ${(props) => props.theme.primary.text};
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  line-height: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    height: auto;
  }
`

export const Description = styled.p`
  margin: 0;
  color: ${(props) => props.theme.tertiary.text};
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  > a {
    width: 256px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    display: none;
  }
`