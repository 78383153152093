'use client'

import { useSignUp } from '@/shared/hooks';
import Button from "@/shared/ui/Button";
import { SectionTitle, Subtitle } from "@/shared/ui/styled";
import { constants } from "../../model";
import * as SC from './styled';

function Features() {
  const { signUpClick, signUpLink } = useSignUp('get_started_click', { type: 'third_block' })

  return (
    <SC.Wrapper>
      <SectionTitle $maxWidth={700}>Discover the Powerful Features of ESIM Plus for Business</SectionTitle>
      <Subtitle>Maximize Efficiency and Connectivity with Our Advanced Virtual Number Management System</Subtitle>
      <SC.ContentWrapper>
        {constants.content.map((data) => (
          <SC.Card key={data.title}>
            <SC.Image src={data.image} alt={data.alt} width={250} height={250} />
            <SC.Title>{data.title}</SC.Title>
            <SC.Description>{data.description}</SC.Description>
          </SC.Card>
        ))}
      </SC.ContentWrapper>
      <SC.ButtonWrapper>
        <Button
          onClick={signUpClick}
          as='a'
          href={signUpLink}
          label='Create Account'
        />
      </SC.ButtonWrapper>
    </SC.Wrapper>
  )
}

export default Features;