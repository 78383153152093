'use client';

import styled, { css } from 'styled-components';

export const LinkButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  color: ${(props) => props.theme.common.main};
  font-weight: 300;
  font-size: inherit;
  cursor: pointer;
`;

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 50px;
  max-width: 1240px;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.primary.text};

   @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    padding: 0 16px;
  }
`;

export const FullSizeContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 50px;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.primary.text};

   @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    padding: 0 16px;
  }
`;

export const SectionTitle = styled.h2<{ $maxWidth?: number }>`
  margin: 0 auto;
  color: ${(props) => props.theme.primary.text};
  text-align: center;
  font-size: 48px;
  line-height: 1.3;
  font-weight: 600;

  ${(props) => props.$maxWidth && css`
    max-width: ${props.$maxWidth}px;
  `}

  @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    font-size: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    font-size: 32px;
    line-height: 1.25;
  }
`;

export const Subtitle = styled.p`
  max-width: 700px;
  margin: 15px auto 0; 
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.theme.secondary.text};

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin: 5px 0 0; 
    font-size: 16px;
    line-height: 24px;
  }
`;