'use client'

import { useCallback } from 'react';
import { useAnalyticsContext } from '@/context/AnalyticsContext';
import { usePageContext } from '@/context/PageContextProvider';
import Button from '@/shared/ui/Button';
import { SectionTitle, Subtitle } from '@/shared/ui/styled';
import * as SC from './styled';

function Section() {
  const { sendSafeMixpanelEvent, sendSafeFbqEvent, sendSafeGtagEvent } = useAnalyticsContext();
  const { source } = usePageContext();

  const handleContactUs = useCallback(() => {
    sendSafeMixpanelEvent('track', 'contact_us_click', { source })
    sendSafeGtagEvent('contact_us_click', { source })
    sendSafeFbqEvent('Contact')
  }, [sendSafeMixpanelEvent, sendSafeGtagEvent, sendSafeFbqEvent, source]);

  return (
    <SC.Wrapper>
      <SectionTitle>Have more questions?</SectionTitle>
      <Subtitle>Reach out to our Support team, always ready to help you find the most advantageous solution for your business.</Subtitle>
      <SC.ButtonWrapper>
        <Button
          onClick={handleContactUs}
          as='a'
          href="mailto:support@esimplus.me?subject=Virtual Phone Number for business"
          label="Contact Us"
        />
      </SC.ButtonWrapper>
    </SC.Wrapper>
  )
}

export default Section