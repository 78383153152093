import centralized_messaging from '@/public/assets/centralized_messaging.png';
import customer_support from '@/public/assets/customer_support.png';
import flexible_number from '@/public/assets/flexible_number.png';
import hasslefree_transactions from '@/public/assets/hasslefree_transactions.png';
import organize_with_ease from '@/public/assets/organize_with_ease.png';
import seamless_control from '@/public/assets/seamless_control.png';

export const content = [
  {
    title: 'Seamless Control',
    description: 'Simplify how you manage your virtual phone numbers with our intuitive dashboard',
    image: seamless_control,
    alt: 'Seamless Control',
  },
  {
    title: 'Flexible Number Allocation',
    description: 'Add new numbers or remove unused ones with just a few clicks, adapting to your business needs as they evolve',
    image: flexible_number,
    alt: 'Flexible Number Allocation',
  },
  {
    title: 'Organize with Ease',
    description: 'Assign custom labels to your numbers for easy identification and management',
    image: organize_with_ease,
    alt: 'Organize with Ease',
  },
  {
    title: 'Centralized Messaging',
    description: 'View all your SMS messages from various numbers in one unified window',
    image: centralized_messaging,
    alt: 'Centralized Messaging',
  },
  {
    title: 'Hassle-Free Transactions',
    description: 'Recharge your account using either traditional card payments or modern cryptocurrency options',
    image: hasslefree_transactions,
    alt: 'Hassle-Free Transactions',
  },
  {
    title: '24/7 Customer Support',
    description: 'Our dedicated support team is available 24/7 to answer any queries and provide solutions',
    image: customer_support,
    alt: '24/7 Customer Support',
  },
]