'use client'

import { useSignUp } from '@/shared/hooks';
import Button from '@/shared/ui/Button';
import { Container } from 'shared/ui/styled';
import * as SC from './styled';

function Section() {
  const { signUpClick, signUpLink } = useSignUp('get_started_click', { type: 'fourth_block' })

  return (
    <Container>
      <SC.Wrapper>
        <SC.TextContainer>
          <h2>Unsure if our numbers are the right fit for your business?</h2>
          <p>Contact our support team with the code word <b>'BUSINESS'</b> to test SMS deliverability for free!</p>
        </SC.TextContainer>
        <SC.ButtonWrapper>
          <Button
            onClick={signUpClick}
            type="button"
            as="a"
            label='Create Account'
            href={signUpLink}
          />
        </SC.ButtonWrapper>
      </SC.Wrapper>
    </Container>
  )
}

export default Section;