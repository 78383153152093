'use client';

import React from 'react';
import Image from 'next/image';
import devices from '@/public/assets/devices.webp';
import { DownloadAppButtons, DownloadAppQRCode } from '@/entities/download-app';
import Icon from '@/shared/ui/Icon';
import { SectionTitle } from '@/shared/ui/styled';
import { constants } from '../../model';
import * as SC from './styled';

function Section() {

  return (
    <SC.Wrapper>
      <SectionTitle>
        Access Your Numbers Anytime with Our Mobile App and Enjoy the Flexibility of Making and Receiving Calls on the Go
      </SectionTitle>
      <SC.Content>
        <div>
          <SC.AdvantagesList>
            {constants.advantagesList.map((data) => (
              <li key={data.text}>
                <Icon width={24} height={24} component={data.icon} />
                {data.text}
              </li>
            ))}
          </SC.AdvantagesList>
          <SC.DownloadAppWaysWrapper>
            <SC.QRCodeWrapper>
              <DownloadAppQRCode />
            </SC.QRCodeWrapper>
            <SC.Info>
              <SC.Text>Scan it with the camera to download the app</SC.Text>
              <DownloadAppButtons placement="full_section" />
            </SC.Info>
          </SC.DownloadAppWaysWrapper>
        </div>
        <SC.ImageWrapper>
          <Image width={640} height={850} src={devices} alt="devices" />
        </SC.ImageWrapper>
      </SC.Content>
    </SC.Wrapper>
  );
}

export default Section;
